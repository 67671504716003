:root {
  --primary-color: #5bafb1;
  --home-page-rgb: 91, 175, 177;
  --hypno-page-rgb: 215, 223, 43;
  --energy-page-rgb: 109, 209, 226;
  --faq-page-rgb: 100, 165, 226;
  --new-color: 199, 109, 175;
  --about-page-rbg: 223, 231, 103;
  --resource-page-rgb: 100, 165, 226;
  --gradiant-color: rgba(255, 255, 255, 0.1);
  --deskgradiant-color: rgba(1, 1, 1, 0);
  --energy-second-layer: #cac2be;
  --books-font-color: #9da879;
}
@font-face {
  font-family: futura;
  src: url("./fonts/futura_light.ttf");
}
body {
  font-family: futura !important;
}
p {
  font-size: 17px;
}
h2 {
  padding: 10px 10px 0px 10px;
  text-shadow: 3px 5px 7px rgba(1, 1, 1, 0.13);
  font-weight: 400 !important;
}
h5 {
  font-size: 20px !important;
}
.faqCol hr {
  width: 200px;
  background-color: rgba(var(--faq-page-rgb), 1);
  height: 1px;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.bg-primary-color {
  background-color: var(--primary-color);
}

.layerOne {
  text-align: center;
  color: var(--white);
  padding: 10px 40px 50px 40px !important;
}
.layerOne p {
  padding: 0 20px;
  margin-bottom: 0px;
}
.layerOne a {
  color: #ffffff;
}
.homeSecondLayer p {
  padding: 0 5px;
}
.faqCol p {
  padding: 0 5px;
}
.shadowbox p {
  margin-bottom: 10px;
}
.shadowbox {
  box-shadow: 2px 38px 14px -10px rgba(0, 0, 0, 0.55);
}
.homeBoxClass {
  background-color: rgba(var(--home-page-rgb), 0.8);
  padding: 20px;
}
.hypnoBox {
  background-color: rgba(var(--hypno-page-rgb), 0.8);
}
.hypnocol,
.hypnocol a {
  color: #053842;
}
.hypnoBox p {
  margin-bottom: 2px;
}
.energyBox {
  background-color: rgba(var(--energy-page-rgb), 0.8);
  padding-bottom: 20px;
}
.homeLayerOne {
  background-image: linear-gradient(
      to bottom,
      rgba(var(--home-page-rgb), 0.5) 35%,
      var(--gradiant-color)
    ),
    url("./images/homeBanner.jpg");
  background-size: cover;
}
.deskhomeLayerOne {
  background-image: url("./images/desktop/homeBanner.jpg");
  background-size: cover;
}
.homeOverlayClass {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--home-page-rgb), 1) 5%,
    var(--deskgradiant-color) 95%
  );
}
.hypnoLayerOne {
  background-image: linear-gradient(
      to bottom,
      rgba(var(--hypno-page-rgb), 1) 15%,
      var(--gradiant-color) 35%
    ),
    url("./images/hypnoBackground.jpg");
  background-size: cover;
}
.deskhypnoLayerOne {
  background-image: url("./images/desktop/hypnoBanner.jpg");
  background-size: cover;
  height: 400px;
}
.hypnoOverlay {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--hypno-page-rgb), 1) 50%,
    var(--deskgradiant-color)
  );
}
.deskaboutLayerOne {
  background-image: url("./images/desktop/aboutBanner.jpg");
  background-size: cover;
  height: 400px;
}

.energyLayerOne {
  background-image: linear-gradient(
      to bottom,
      rgba(var(--energy-page-rgb), 1) 15%,
      var(--gradiant-color) 35%
    ),
    url("./images/newimg.jpg");
  background-size: cover;
}
.deskenergyLayerOne {
  background-image: url("./images/desktop/energyBanner.jpg");
  background-size: cover;
  height: 400px;
}
.energyOverlay {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--energy-page-rgb), 1) 35%,
    var(--deskgradiant-color) 95%
  );
  color: #222222;
}
.faqLayerOne {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--faq-page-rgb), 1) 5%,
    var(--gradiant-color) 27%
  );
}
.aboutLayerOne {
  background-image: linear-gradient(
      to bottom,
      rgba(var(--about-page-rbg), 1) 9%,
      var(--gradiant-color) 16%
    ),
    url("./images/aboutPerson.jpg");
  background-size: cover;
  background-position: bottom;
}
.aboutContact {
  background-image: linear-gradient(
      to top,
      rgba(var(--about-page-rbg), 0.7) 40%,
      var(--gradiant-color) 95%
    ),
    url("./images/aboutBackground.png");
  background-size: 110%;
  background-repeat: no-repeat;
  padding: 0px 30px;
}
.resourceLayerOne {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--resource-page-rgb), 1) 85%,
    var(--gradiant-color) 100%
  );
}
.homeSecondLayer {
  background-image: linear-gradient(
      to bottom,
      rgba(var(--new-color), 0.7) 100%,
      var(--gradiant-color) 95%
    ),
    url("./images/homeRock.jpg");
  background-size: 100%;
}
.faqtxtcol {
  color: #053842;
}
.layerOnes {
  text-align: center;
  padding: 0px 30px 30px 30px;
}
.layerOnes label {
  font-size: 18px;
  font-weight: 600;
}

.layerOnes .faqLink {
  border: 1px solid rgba(var(--faq-page-rgb), 1);
}

.lm-btn {
  width: 260px;
  color: var(--white) !important;
  border-radius: 0px !important;
}
.faqLayerOne {
  padding-bottom: 20px !important;
}
.abotTxtCol {
  color: #9da879;
}
.blackglass {
  background-color: rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 25px;
  color: #ffffff;
}
.blackglass p {
  margin-bottom: 0px;
}
.blackglass a {
  color: var(--white);
}
.aboutForm {
  text-align: center;
}
.learn-more {
  padding: 20px 28px 0px 28px !important;
}
.homeThirdLayer {
  background-color: rgba(var(--energy-page-rgb), 0.8);
}
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.chak span,
.chak p {
  display: table-cell;
}
.chak span {
  width: 220px;
  text-align: right;
  padding-right: 20px;
  color: var(--white);
}
.cha h3 {
  color: var(--energy-second-layer);
  font-size: 2rem;
  margin-bottom: 40px;
  padding-left: 50%;
  padding-top: 25px;
}
.energySecondaryLayer {
  max-width: 380px;
  background-image: url("./images/meditation.png");
  background-position: top left;
  background-size: 180px 510px;
  background-repeat: no-repeat;
}
.tspink {
  text-shadow: 0 0 2px #ff00fc, 0 0 4px #ff00fc, 0 0 6px #ff00fc,
    0 0 8px #ff00fc, 0 0 10px #ff00fc, 0 0 12px #ff00fc, 0 0 15px #ff00fc,
    0 0 17px #ff00fc, 0 0 20px #ff00fc;
}
.tspurple {
  text-shadow: 0 0 2px #a904cf, 0 0 4px #a904cf, 0 0 6px #a904cf,
    0 0 8px #a904cf, 0 0 10px #a904cf, 0 0 12px #a904cf, 0 0 15px #a904cf,
    0 0 17px #a904cf, 0 0 20px #a904cf;
}
.tsblue {
  text-shadow: 0 0 2px #381ce3, 0 0 4px #381ce3, 0 0 6px #381ce3,
    0 0 8px #381ce3, 0 0 10px #381ce3, 0 0 12px #381ce3, 0 0 15px #381ce3,
    0 0 17px #381ce3, 0 0 20px #381ce3;
}
.tsgreen {
  text-shadow: 0 0 2px #09d603, 0 0 4px #09d603, 0 0 6px #09d603,
    0 0 8px #09d603, 0 0 10px #09d603, 0 0 12px #09d603, 0 0 15px #09d603,
    0 0 17px #09d603, 0 0 20px #09d603;
}
.tsyellow {
  text-shadow: 0 0 2px #ffe400, 0 0 4px #ffe400, 0 0 6px #ffe400,
    0 0 8px #ffe400, 0 0 10px #ffe400, 0 0 12px #ffe400, 0 0 15px #ffe400,
    0 0 17px #ffe400, 0 0 20px #ffe400;
}
.tsorange {
  text-shadow: 0 0 2px #e75d02, 0 0 4px #e75d02, 0 0 6px #e75d02,
    0 0 8px #e75d02, 0 0 10px #e75d02, 0 0 12px #e75d02, 0 0 15px #e75d02,
    0 0 17px #e75d02, 0 0 20px #e75d02;
}
.tsred {
  text-shadow: 0 0 2px red, 0 0 4px red, 0 0 6px red, 0 0 8px red, 0 0 10px red,
    0 0 12px red, 0 0 15px red, 0 0 17px red, 0 0 20px red;
}
.bookImg {
  height: 220px;
  width: 150px;
}
.bookList {
  text-align: center;
  color: var(--books-font-color);
}

.bookList a {
  color: var(--books-font-color);
}
.websites {
  background-color: rgba(var(--resource-page-rgb), 1);
  color: var(--white);
  padding-top: 50px;
  padding-bottom: 50px;
}
.websites a {
  color: var(--white);
  text-decoration: underline;
}
.videos p {
  text-align: left;
}
.videos iframe {
  height: 315px;
  width: 540px;
}
.form-control {
  border-radius: 0px !important;
  padding: 20px 15px !important;
}
.btn-primary {
  border: none !important;
  font-size: 18px !important;
  padding: 10px 30px !important;
  margin-bottom: 50px;
  border-radius: 0px !important;
  width: 120px;
}
.aboutbtn {
  background-color: var(--books-font-color) !important;
}
.homebtn {
  background-color: #387787 !important;
}
.contactForm {
  text-align: center;
}
.foot p {
  color: var(--books-font-color);
  text-align: center;
}
.mobileIpadwidth {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
.chak p {
  line-height: 18px;
}
.c {
  text-align: center;
  display: block;
  position: relative;
  width: 80%;
  margin: 100px auto;
}
._404 {
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 250px;
  letter-spacing: 15px;
}
._1 {
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
}
._2 {
  text-align: center;
  display: block;
  position: relative;
  font-size: 20px;
}
.text {
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  /* top: 256.301px; */
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}

.btn-not-found,
.btn-not-found:hover {
  background-color: #33cc99;
  position: relative;
  display: inline-block;
  width: 358px;
  padding: 5px;
  z-index: 5;
  font-size: 25px;
  margin: 0 auto;
  color: #ffffff;
  text-decoration: none;
  margin-right: 10px;
}
.right {
  float: right;
  width: 60%;
}

hr {
  padding: 0;
  border: none;
  border-top: 5px solid #fff;
  color: #fff;
  text-align: center;
  margin: 0px auto;
  width: 420px;
  height: 10px;
  z-index: -10;
}

.country-list {
  text-align: left;
  color: #000;
}
.react-tel-input .form-control {
  width: 100% !important;
  padding-left: 50px !important;
}
.selectdiv .form-control {
  padding: 8px 10px !important;
}

.nav-links-desktop {
  width: 100%;
  background-color: #ffffff;
  align-items: center;
  z-index: 1;
  color: var(--books-font-color);
  margin-top: 3.2rem;
}
.nav-links-desktop a {
  color: var(--books-font-color);
  padding: 1.5rem;
  font-size: 20px;
  margin-top: 20px;
}
.nav-links-desktop a.active,
.nav-links-desktop a:hover {
  color: var(--books-font-color) !important;
  text-decoration: none;
}

.DesklayerOne {
  color: var(--white);
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.overlaybox {
  padding: 80px 80px !important;
}
.desktopWidth {
  max-width: 1540px;
}
.desktopWidth p {
  font-size: 22px;
  line-height: 28px;
}
.desktopWidth h2 {
  font-size: 40px;
  text-shadow: 3px 5px 7px rgba(1, 1, 1, 0.13);
  padding-left: 0px !important;
}
.bannerTextDesk {
  padding: 100px 0px 100px 100px !important;
}
.bannerCaption {
  padding: 20px;
  background-color: rgba(55, 119, 134, 0.6);
}

.deskHomePro {
  padding: 0 3px 0 0 !important;
}
.proDeskBack {
  background-image: linear-gradient(
      to bottom,
      rgba(var(--new-color), 0.7) 100%,
      var(--gradiant-color)
    ),
    url("./images/homeRock.jpg");
  background-size: cover;
  padding: 40px;
  color: #ffffff;
}
.img-pro-fluid {
  width: 100% !important;
}

.desktopWidth .deskButton {
  width: 100%;
  margin-top: 50px;
  height: 50px;
  font-size: 20px;
  padding-top: 8px;
  margin-bottom: 10px;
}
.desktopWidth i {
  font-size: 16px;
}
.deskHomeForm {
  padding: 0px 0px 0px 3px !important;
}
.homeFromBack {
  background-color: rgba(var(--energy-page-rgb), 0.8);
  padding: 50px 60px;
}
.deskContactForm {
  color: var(--white);
}
.desktopWidth .selectdiv select {
  margin: 0px 0px;
}
.desktopWidth .contactForm .col-md-6 {
  padding-left: 0px !important;
}
.desktopWidth .form-txt {
  height: 100px;
}
.desktopWidth .DeskbtnClass {
  text-align: left;
}
.desktopWidth .layerOnes {
  text-align: left;
  padding: 0px 30px 50px 0px;
}
.deskHomeForm .homeFromBack {
  min-height: 800px;
  max-height: 800px !important;
}
.deskHomePro .proDeskBack {
  min-height: 795px;
  max-height: 800px !important;
}
.desktopWidth .react-tel-input .form-control,
.desktopWidth .selectdiv select,
.desktopWidth .form-control {
  height: 50px;
}
.desktopWidth .form-txt {
  height: 115px;
}
.alert {
  padding: 2px !important;
}
.DeskHypnoLayTwo {
  background-color: rgba(var(--energy-page-rgb), 0.8);
  color: var(--white);
  padding: 50px 60px !important;
}
.DeskHypnoLayTwo .DeskHelp {
  padding-right: 70px;
}
.hypnoDeskDetails p {
  line-height: 14px;
}
.deskEngPad {
  padding-left: 0px !important;
}
.deskEnergyHealing {
  background-color: rgba(var(--energy-page-rgb), 0.8);
  color: var(--white);
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.engBack {
  background-image: url("./images/desktop/deskMedi.png");
  height: 860px;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 300px 680px;
}
.deskEngTitle {
  padding: 50px !important;
}
.deskEngPara {
  padding: 50px 180px 50px 0px !important;
}
.deskEngPara a {
  color: var(--white);
}
.deskEngTitle p {
  padding: 20px 80px 20px 20px;
  color: #222222;
}
.desktopWidth .chak span {
  width: 100px;
  position: absolute;
  left: -60px;
}
.deskChakra h2 {
  color: #cac2be;
  margin-top: 80px;
}
.deskChakra p {
  color: #053842;
  max-width: 310px;
}
.deskChakra .tsorange {
  margin-top: 20px;
}
.DeskFaqHead {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--faq-page-rgb), 1) 5%,
    var(--gradiant-color) 15%
  );
}
.DeskFAQLAYERCOlor {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--faq-page-rgb), 1) 15%,
    var(--gradiant-color) 55%
  );
  padding: 10px 50px;
}
.DeskFaqHead h2 {
  color: var(--white);
  text-align: center;
  padding: 50px;
}
.DeskFaqQA {
  padding-right: 180px !important;
}
.DeskFaqQA h5 {
  color: var(--white);
  padding: 50px 10px 10px;
}
.DeskFAQLAYERCOlor h5 {
  padding: 30px 10px 2px;
}
.DeskFaqQA hr {
  width: 100%;
  background-color: #c76daf;
  background-color: rgba(var(--faq-page-rgb), 1);
  height: 1px;
}
.DeskFaqQA .faqLink {
  text-align: center;
}
.DeskFaqQA .layerOnes label {
  text-align: left;
}
.sp {
  display: flex;
  justify-content: space-around;
}
.comonents {
  max-width: 600px;
}
.deskaboutLayerOne p,
.deskaboutLayerOne i {
  color: #043842;
}
.DeskAbout {
  padding: 0px !important;
}
.DeskAbout .blackglass {
  background-color: var(--white);
  text-align: left;
  color: #3a5a48;
  padding: 50px;
}
.DeskAbout .blackglass a {
  color: #3a5a48;
}
.DeskProfAbout {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.DeskAboutBack {
  background-color: rgba(var(--about-page-rbg), 1);
  padding: 30px 50px !important;
  color: #3a5a48;
}
.DeskAboutBack h2 {
  color: var(--white);
}
.DeskRespurces {
  padding-top: 60px;
}

.DeskResourceBack {
  background-image: linear-gradient(
    to bottom,
    rgba(var(--resource-page-rgb), 1) 55%,
    var(--gradiant-color)
  );
  padding-bottom: 80px;
}
.DeskRespurces h2 {
  text-align: center;
  color: var(--white);
}
.DeskRespurces p {
  text-align: left;
}
.DeskbookList .websites {
  color: #053842;
  background-color: var(--white);
  text-align: left;
}
.DeskbookList .websites a {
  color: #053842;
}
.DeskAuthor {
  color: #053842;
}
.DeskBookTitle,
.DeskBookTitle a {
  color: #9da879;
}
.Deskvideos {
  background-color: rgba(var(--resource-page-rgb), 1);
}
.Deskvideos {
  padding: 50px 70px !important;
  color: var(--white);
}
.Deskvideos iframe {
  height: 240px;
  width: 100%;
}
.homeImg {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.aboutLayerOne {
  height: 1080px;
}
@media only screen and (max-width: 1400px) {
  .overlaybox {
    padding: 50px 50px !important;
  }
}
@media only screen and (max-width: 1300px) {
  .deskChakra p {
    font-size: 19px;
  }
  .deskEngPara {
    padding: 50px 140px 50px 0px !important;
  }
}
@media only screen and (max-width: 800px) {
  .bg-primary-color {
    text-align: center;
  }
  .container-fluid {
    box-shadow: none;
    border: none;
  }
  .layerOne {
    padding: 25px 30px 50px 30px;
  }
  .navbar {
    top: -17px;
  }
  .p-2 {
    padding: 0.7rem !important;
  }
  .videos iframe {
    height: 200px;
    width: 100%;
  }
  ._404 {
    font-size: 30vw;
    height: 150px;
  }
  .btn-not-found,
  .btn-not-found:hover {
    width: 260px;
  }
  hr {
    width: 30vw;
  }
  .text {
    font-size: 30px;
  }
}
@media only screen and (max-width: 620px) {
  .aboutLayerOne {
    background-image: linear-gradient(
        to bottom,
        rgba(var(--about-page-rbg), 1) 20vw,
        var(--gradiant-color) 32vw
      ),
      url("./images/aboutPerson.jpg");
    height: 182vw;
  }
}

@media only screen and (max-width: 460px) {
  .aboutLayerOne {
    height: 840px;
    background-image: linear-gradient(
        to bottom,
        rgba(var(--about-page-rbg), 1) 18%,
        var(--gradiant-color) 24%
      ),
      url("./images/aboutPerson.jpg");
    background-position: center;
  }
}
@media only screen and (max-width: 400px) {
  .chak span {
    width: 180px;
    padding-right: 20px;
  }
  .energySecondaryLayer {
    background-size: 150px 475px;
  }
  .videos iframe {
    height: 180px;
  }
  .layerOne p {
    padding: 0 8px;
  }
}
@media only screen and (max-width: 350px) {
  .layerOne {
    padding: 20px 20px 50px 20px !important;
  }
  .chak span {
    width: 140px;
    padding-right: 10px;
  }
  .energySecondaryLayer {
    background-size: 120px 520px;
  }
  .bookImg {
    height: 180px;
    width: 120px;
  }
  .videos iframe {
    height: 140px;
  }
}
@media only screen and (max-width: 290px) {
  .layerOne {
    padding: 2px 0px 50px 0px !important;
  }
}
.selectdiv {
  position: relative;
  /*Don't really need this just for demo styling*/
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  display: none;
}

.selectdiv:after {
  content: "\2759\276F";
  font-size: 17px;

  color: #222222;
  -webkit-transform: scale(1.2, 1) rotate(90deg);
  -moz-transform: scale(1.2, 1) rotate(90deg);
  -ms-transform: scale(1.2, 1) rotate(90deg);
  transform: scale(1.2, 1) rotate(90deg);
  transform: scale(1.2, 1) rotate(90deg);
  right: 11px;
  /*Adjust for position however you want*/
  font-weight: 900;
  top: 8px;
  padding: 0 0 1px;
  /*left line */
  transition: ease-in-out 0.5s;
  position: absolute;
  pointer-events: none;
}
.open:after {
  transform: scale(1.2, 1) rotate(270deg);
}
.selectdiv select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add some styling */
  height: 43px;
  display: block;
  width: 100%;
  margin: 5px 0px;
  padding: 0px 24px;
  font-size: 16px;
  line-height: 1.75;
  color: #222222;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  -ms-word-break: normal;
  word-break: normal;
}

.flex-container{
    display: flex;
   justify-content: center;
   position: relative;

}
.testimontial {
  color: #fff;
  padding: 40px 0 40px 40px;
  z-index: 1;
  /* background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 5%,
    var(--deskgradiant-color) 15%
  ); */
}

.testimontial, .testis {
  width: 92%;
  height: 600px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.testimonialbox1 {
  height: 600px;
  -webkit-mask-image: linear-gradient(180deg, #000 50%, transparent);
  mask-image: linear-gradient(180deg, #000 50%, transparent);
  padding-right: 40px;
}

.testimonialbox1:hover {
  height: 100%;
  overflow-y: scroll;
  -webkit-mask-image: none;
  mask-image: none;
}

/* .testimontial:hover {
  background-image: linear-gradient(
    to top,
    rgba(255, 255, 255, 1) 5%,
    var(--deskgradiant-color) 5%
  );
} */

.quoteIcon {
  height: 65px;
  width: 65px;
  border-radius: 34px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 13px 15px 13px 16px;
}

@media only screen and (max-width: 1100px) {
  .quoteIcon {
    height: 55px;
    width: 55px;
    padding: 13px 10px 13px 14px;
  }
}

@media only screen and (max-width: 700px) {
.testimontial {
    width: 100%;
  }
}

